import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { createcatogery, updatecategory} from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Switch, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useGlobalStyles from '../../utils/globalStyles';
import AWS from 'aws-sdk';
import { imageBaseUrl } from '../../utils/imageLink';
import S3 from 'aws-sdk/clients/s3';

const CREATE_CATEGORY = gql`
  ${createcatogery}
`;
const EDIT_CATEGORY = gql`
  ${updatecategory}
`;



function AddCategory(props) {
  const { onClose } = props;
  const formRef = useRef();
  const fileInputRef = useRef(null)
  const mutation = props.vendor ? EDIT_CATEGORY : CREATE_CATEGORY;
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [formData, setFormData] = useState({
    id: props.vendor ? props.vendor._id : "",
    images: props.vendor ? props.vendor.images : [],
    isActive: props.vendor ? props.vendor.isActive : false,
    // subcategoryIds: props.vendor ? props.vendor.subcategoryIds : '',
    name: props.vendor ? props.vendor.name : '',
    description: props.vendor ? props.vendor.description : '',
    subcategoryIds: props.vendor ? props.vendor.subcategoryIds : []
  },
  );

  
  const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
  const [openSnackk, setopenSnackk] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [images, setImages] = useState(props.vendor ? props.vendor.images : []); // State to store uploaded images
  const [imageName, setImageName] = useState(null)
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false)
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      // Update the images state with the new image, replacing the previous one
      setImages([reader.result]);
      setFormData(prevState => ({
        ...prevState,
        images: [reader.result]
      }));
    };
    reader.readAsDataURL(file);
  };

  // const handleDeleteImage = (index) => {
  //   setImages(prevImages => prevImages.filter((_, i) => i !== index));
  // };
  const allowedTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'video/mp4',
    'video/quicktime',
    'audio/mpeg',
    'audio/wav',
    // Add more supported types as needed
  ];
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setImageName(selectedFile.name)
    if (allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      alert('Invalid file type. Only images and PDFs are allowed.');
    }
  };

  const uploadFile = async () => {
    setUploading(true);
    const S3_BUCKET = "nativenest"; // Replace with your bucket name
    const REGION = "ap-south-1"; // Correct region code
    const getContentType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      const contentTypes = {
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        pdf: 'application/pdf',
        mp4: 'video/mp4',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        // Add more content types as needed
      };
      return contentTypes[extension] || 'application/octet-stream';
    };
  
    AWS.config.update({
      // accessKeyId: "AKIAVRUVQVTHJR36HMGG",
      // secretAccessKey: "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",
       accessKeyId: "AKIAVRUVQVTHIW2AHBGP",
      secretAccessKey: "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",
    });


    const folderStructure = process.env.REACT_APP_IMAGE_FOLDERSTRUCTURE;
    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const contentType = getContentType(file.name);
  
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folderStructure}/${file.name}`, // File path in S3 bucket
      Body: file,
      ContentType: contentType, // Add ContentType here
      // ACL: 'public-read' // Set ACL to public-read
    };
    try {
      const upload = await s3.putObject(params).promise();
      const imageUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folderStructure}/${file.name}`;
console.log(upload ,"upload" , imageUrl ,"imageURl")
alert("File uploaded successfully.");
     setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, imageUrl],
      }));
      setUploading(false);
    } catch (error) {
      setUploading(false);
      alert("Error uploading file: " + error.message); // Inform user about the error
   
      // alert("Error uploading file: " + error.message); // Inform user about the error
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update form data state
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: '' });
    }
  };
  const validateFormData = () => {
    let errors = {};
    if (!formData.id) errors.id = 'Product ID is required';
    if (!formData.name) errors.name = 'Product Name is required';
    if (!formData.description) errors.description = 'Discription Name is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onCompleted = (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Category Updated Successfully') : t('Category  Added Successfully');
    errorSetter('');
    successSetter(message);
    setTimeout(hideAlert, 1000);
    setopenSnackk(true)
    props.refetch()
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      const errorMessage = graphQLErrors[0].message;
      errorSetter(errorMessage);
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 1000);
    setopenSnackk(true)
    props.refetch()
  };
  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted,
  });

  const clearFields = () => {
    setFormErrors({});
    formRef.current.reset();

    setFormData({
      id: props.vendor ? props.vendor._id : "",
      images: props.vendor ? props.vendor.images : [],
      isActive: props.vendor ? props.vendor.isActive : '',
      subcategoryIds: props.vendor ? props.vendor.subcategoryIds : '',
      name: props.vendor ? props.vendor.name : '',
      description: props.vendor ? props.vendor.description : '',


    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };
  const [formErrors, setFormErrors] = useState({});


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFormData()) {
      return;
    }

    //         e.preventDefault();
    //         // if (onSubmitValidaiton() && !mutateLoading) {
    if (true) {
      !props.vendor ?
        mutate({
          variables: {
            categoryInput: {
              _id: formData.id,
              description: formData.description,
              images: imageBaseUrl + `${folderStructure}/${imageName}`,// Check if this is how your backend expects images
              isActive: formData.isActive,
              subcategoryIds: formData.subcategoryIds,
              name: formData.name
            }
          }
        }) :
        mutate({
          variables: {
            categoryUpdate: {
              description: formData.description,
              images: imageName ? imageBaseUrl + `${folderStructure}/${imageName}` : formData.images, // Check if this is how your backend expects images
              isActive: formData.isActive,
              subcategoryIds: formData.subcategoryIds,
              name: formData.name,
              _id: formData.id,
            }
          }
        });
      // Close the modal after 3 seconds by calling the parent's onClose callback
      setTimeout(() => {
        if (typeof props.onClose === 'function') {
          props.onClose(); // Close the modal
        }
      }, 1000);
    }
  }
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {props.vendor ? t('Edit Category') : t('Add Category')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            {!props.editModal ?

              <>
                <Grid item xs={12} sm={12}>
                  <TextField name="id" label="Category ID"
                    value={formData.id} variant="outlined"
                    fullWidth onChange={handleInputChange}
                    helperText={formErrors.id}
                    error={Boolean(formErrors.id)}
                    inputProps={{ style: inputTextStyle }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    name="name"
                    label="Category Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    helperText={formErrors.name}
                    error={Boolean(formErrors.name)}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                  />
                  <Typography variant="body1" component="span">
                    Current Value: {formData.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="outlined-basic"
                    name="description"
                    label="Description "
                    variant="outlined"
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange}
                    inputProps={{ style: inputTextStyle }}
                    helperText={formErrors.description}
                    error={Boolean(formErrors.description)}
                  />
                  <Typography variant="body1" component="span">
                    Current Value: {formData.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(props.vendor && previewImage == null) ?
                    <>
                      {images.map((image, index) => (
                        <div key={index}>
                          <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                        </div>
                      ))}
                    </> :
                    <>
                      {previewImage && (
                        <div>
                          <img src={previewImage} alt="Preview" style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                        </div>
                      )}

                    </>

                  }
<Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    Choose Image
                    <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />
                  </Button>
                  <Button onClick={uploadFile} disabled={!file} className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                    {uploading ? 'Uploading...' : 'Upload  File'}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                      Status
                    </Typography>
                    <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                  </div>
                </Grid>

              </>
              :
              ( // Conditionally render the second form fields based on editModal prop
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField name="id" label="  Category ID" helperText={formErrors.id}
                      error={Boolean(formErrors.id)} value={formData.id} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="outlined-basic"
                      name="title"
                      label="Category Name"
                      variant="outlined"
                      fullWidth
                      helperText={formErrors.name}
                      error={Boolean(formErrors.name)}
                      value={formData.title}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                    />
                    <Typography variant="body1" component="span">
                      Current Value: {formData.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="outlined-basic"
                      name="description"
                      label="Description "
                      variant="outlined"
                      fullWidth
                      helperText={formErrors.description}
                      error={Boolean(formErrors.description)}
                      value={formData.description}
                      onChange={handleInputChange}
                      inputProps={{ style: inputTextStyle }}
                    />
                    <Typography variant="body1" component="span">
                      Current Value: {formData.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {images.map((image, index) => (
                      <div key={index}>
                        <img src={image} alt={`Uploaded ${index}`} style={{ width: '100px', height: 'auto', marginRight: '10px' }} />
                      </div>
                    ))}
                    <Button component="label" variant="outlined" className={globalClasses.dashbordBtn} startIcon={<CloudUploadIcon />}>
                      Upload file
                      <input type="file" onChange={handleFileUpload} ref={fileInputRef} style={{ display: 'none' }} />
                    </Button>
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="span" style={{ color: formData.isActive ? 'gray' : 'red' }}>
                        Status
                      </Typography>
                      <Switch name="isActive" checked={formData.isActive} onChange={handleSwitchChange} />
                    </div>
                  </Grid>

                </>
              )
            }
          </Grid>
          <DialogActions>
            <Button
              type='submit'
              className={globalClasses.dashbordBtn}
              disabled={mutateLoading}

            >
              {props.vendor ? t('Update') : t('Add')}
            </Button>
            <Button
              className={globalClasses.modalCancleBtn}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(AddCategory);
