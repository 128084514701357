import React, { useRef, useState } from 'react'
import { useMutation, gql, useApolloClient } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import { AddBulkCreditToNestCash } from '../../apollo'
import {
  Button,
  Grid,
  TextField,
  DialogActions,
  Alert,
  Snackbar,
  Container,
  Box,
  Typography
} from '@mui/material'
import useGlobalStyles from '../../utils/globalStyles'
import Header from '../Headers/Header'

const ADD_BULK_CREDIT_TO_NESTCASH = gql`
  ${AddBulkCreditToNestCash}
`

function BulkCreditToNestCash(props) {
  const globalClasses = useGlobalStyles()
  const { onClose = () => {} } = props
  const formRef = useRef()
  const userDetailsStr = localStorage.getItem('user-native')
  const userDetails = JSON.parse(userDetailsStr)
  const inputTextStyle = { color: 'black' }
  const mutation = ADD_BULK_CREDIT_TO_NESTCASH
  const [error, errorSetter] = useState('')
  const client = useApolloClient()
  const [success, successSetter] = useState('')
  const [openSnackk, setOpenSnackk] = useState(false)
  const { t } = props
  const [formData, setFormData] = useState({
    amount: '',
    applyPercent: '',
    expiry: '',
    reason: '',
    phones: ''
  })

  const formatDateToISO = dateString => {
    const date = new Date(dateString)
    const isoString = date.toISOString() // This will give you the date in "2024-09-04T00:00:00.000Z" format
    return isoString.replace('Z', '+00:00') // Replacing 'Z' with '+00:00' to match your required format
  }
  const formatToYYYYMMDD = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return date.toISOString().split('T')[0] // Convert to "YYYY-MM-DD" format
  }
  const [StartDate, setStartDate] = useState(formatToYYYYMMDD(formData.expiry))
  console.log(formData)

  const handleFormInputChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }
  const onCompleted = data => {
    const message = t('Bulk Credit Applied Successfully')
    errorSetter('')
    successSetter(message)
    setTimeout(hideAlert, 3000)
    setOpenSnackk(true)
    setFormData({
      amount: '',
      applyPercent: '',
      expiry: '',
      reason: '',
      phones: ''
    })
  }

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('')

    if (graphQLErrors && graphQLErrors.length > 0) {
      errorSetter(graphQLErrors[0].message || 'An error occurred')
    } else if (
      networkError &&
      networkError.result &&
      networkError.result.errors &&
      networkError.result.errors.length > 0
    ) {
      errorSetter(
        networkError.result.errors[0].message || 'A network error occurred'
      )
    } else {
      errorSetter('Something went wrong!')
    }

    setOpenSnackk(true)
    setTimeout(hideAlert, 3000)
  }

  const [mutate, { loading: mutateLoading }] = useMutation(mutation, {
    onError,
    onCompleted
  })

  const hideAlert = () => {
    errorSetter('')
    successSetter('')
    setOpenSnackk(false)
  }

  const onSubmit = e => {
    e.preventDefault()

    mutate({
      variables: {
        bulKCreditInput: {
          amount: parseFloat(formData.amount), // Ensure this is a Float
          applyPercent: parseFloat(formData.applyPercent), // Ensure this is a Float
          expiry: formData.expiry, // Ensure this is a String
          reason: formData.reason, // Ensure this is a String
          phones: formData.phones
            .split(',')
            .map(num => num.trim())
            .filter(num => num.length > 0), // Ensure this is an array of non-empty Strings
          applied: formData.applied // Ensure this is a Boolean
        }
      }
    })

    setTimeout(() => {
      if (typeof props.onClose === 'function') {
        props.onClose()
      }
    }, 1000)
  }

  return (
    <>
      <Header />
      <Container className={globalClasses.flexRow}>
        <Grid
          sx={{
            backgroundColor: 'white',
            py: 4,
            margin: '1rem',
            padding: '1rem'
          }}>
          <form ref={formRef} onSubmit={onSubmit}>
            <Typography variant="h6">{t('Add Nest Cash–Bulk Mode')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Amount:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      name="amount"
                      variant="outlined"
                      fullWidth
                      value={formData.amount}
                      onChange={handleFormInputChange}
                      inputProps={{ style: inputTextStyle, type: 'number' }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Apply Percent:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      name="applyPercent"
                      variant="outlined"
                      fullWidth
                      value={formData.applyPercent}
                      onChange={handleFormInputChange}
                      inputProps={{ style: inputTextStyle, type: 'number' }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Expiry Date:
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      name="expiry Date"
                      variant="outlined"
                      fullWidth
                      type="date"
                      value={StartDate}
                      onChange={value => {
                        const formattedEndDate = formatDateToISO(
                          value.target.value
                        )
                        setStartDate(value.target.value)
                        setFormData({ ...formData, expiry: formattedEndDate })
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ style: inputTextStyle }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Reason/Description:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="reason"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      value={formData.reason}
                      onChange={handleFormInputChange}
                      inputProps={{ style: inputTextStyle }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="top">
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ textAlign: 'left' }}>
                      Enter Customer Phone Number:
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      name="phones"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={10}
                      value={formData.phones}
                      onChange={handleFormInputChange}
                      inputProps={{ style: inputTextStyle, type: 'number' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions>
              <Button
                type="submit"
                className={globalClasses.dashbordBtn}
                disabled={mutateLoading}>
                {t('Add')}
              </Button>
            </DialogActions>
          </form>
        </Grid>
      </Container>
      <Box mt={2}>
        {success && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar
            open={openSnackk}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  )
}

export default withTranslation()(BulkCreditToNestCash)
