import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Header from '../components/Headers/Header'
import CustomLoader from '../components/Loader/CustomLoader'
import DataTable from 'react-data-table-component'
import { customStyles } from '../utils/tableForQuatationSkuDEtails'
import useGlobalStyles from '../utils/globalStyles'
import {
  Container,
  Button,
  Grid,
  Typography,
  Box,
  Switch,
  Modal
} from '@mui/material'
import TableHeader from '../components/TableHeader'
import useStyles from '../components/Section/styles'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Icon } from '@iconify/react'
import ChangeCustomerType from '../components/ChangeCustomerType/ChangeCustomerType'

import { gql } from 'apollo-boost'
import { useApolloClient, useQuery } from '@apollo/client'
import ViewWallet from '../components/ViewWallet/ViewWallet'
import { SentimentVeryDissatisfiedTwoTone } from '@mui/icons-material'
import ViewNestCash from '../components/ViewWallet/ViewNestCash'
import { GetAllCustomerTypes, GetCustomerDetailsById, GetCustomerWallet, getQuotationResponse } from '../apollo'
import AddNestCash from '../components/AddNestCash/AddNestCash'
const GET_DATA = gql`
  ${getQuotationResponse}
`

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`
const GET_CUSTOMER_WALLET = gql`
  ${GetCustomerWallet}
`
const ViewCustomerDetails = props => {
  //   const { t } = props;
  const golbalClasses = useGlobalStyles()
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { t } = props
  const history = useHistory()

  const userType = JSON.parse(localStorage.getItem('user-native')).userType
  const rowDataId = JSON.parse(localStorage.getItem('quotationDetails'))
  const handleBackClick = () => {
    history.push('/cs_admin/manageQuotationData') // Navigate back to ManageCategory component
    localStorage.removeItem('quotationDetails')
  }
  console.log(rowDataId ,"rowDataId")
  const client = useApolloClient()
  const [addVendorModal, setAddVendorModal] = useState(false)
  const [viewWalletModal, setViewWalletModal] = useState(false)
  const [viewNestCashModal, setNestCashModal] = useState(false)
  const [addNestCashModal, setaddNestCashModal] = useState(false)
  const [formattedData, setFormattedData] = useState(null)
  const openEjsInNewTab = (base64String) => {
    try {
      // Step 1: Decode the base64 string
      const decodedString = atob(base64String);
      console.log('Decoded String:', decodedString);
  
      // Step 2: Parse JSON
      let jsonData;
      try {
        jsonData = JSON.parse(decodedString);
      } catch (parseError) {
        throw new Error('Failed to parse JSON from decoded string');
      }
  
      // Step 3: Wrap the data in refreshResponse
      const formattedData = {
        refreshResponse: jsonData
      }
      setFormattedData(formattedData)
      // Step 4: Validate the structure
      if (!formattedData) {
        throw new Error('Invalid data format: refreshResponse not found');
      }
  console.log(formattedData ,"formattedData")
     
     
  
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  const { data: quotaionDetails, refetch } = useQuery(GET_DATA, {
    skip: !rowDataId, // Skip query if rowDataId is not available
    variables: { quotationId: rowDataId ? rowDataId._id : null },
    onCompleted :data=>{
        openEjsInNewTab(data.getQuotationResponse);
    },
    onError: error => {
      const isForbiddenError =
        error &&
        error.graphQLErrors.some(err => err.extensions.code === 'FORBIDDEN')
      if (isForbiddenError) {
        localStorage.removeItem('user-native')
        localStorage.removeItem('restaurant_id')
        client.clearStore()
        props.history.push('/auth/login')
      }
    }
  })


  const rowData = rowDataId
  
 
 

  // Example of using the state data
  useEffect(() => {
    if (rowData) {
      // Perform actions with rowData as needed
    }
  }, [addVendorModal])

  useEffect(() => {
    localStorage.removeItem('restaurant_id')
  }, [])

  if (!rowData) {
    return <div>Loading...</div>
  }
  const columns = [
    {
      name: 'Sl No',
      selector: 'slNo',
      sortable: true, // Add sortable feature if needed
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true, // Add sorting capability for the name
    },
    {
      name: 'Product ID',
      selector: 'productId',
      sortable: true,
    },
    {
      name: 'Stock ID',
      selector: 'stockId',
      sortable: true,
    },
    {
      name: 'Order Quantity',
      selector: 'orderQuantity',
      sortable: true,
    },
    {
      name: 'Sales Quantity',
      selector: 'salesQuantity',
      sortable: true,
    },
    {
      name: 'Price',
      selector: 'price',
      sortable: true,
      cell: row => `$${row.price}` // Formatting the price with a currency symbol
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      cell: row => `$${row.total}` // Formatting the total with a currency symbol
    },
    {
      name: 'Message',
      selector: 'message',
      cell: row => row.message || 'N/A', // Display 'N/A' if message is null
      wrap: true, // Enable text wrapping for longer messages
    }
  ];
  
  const formatDate = timestamp => {
    const date = new Date(timestamp)
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }
  return (
    <>
      <Header />

      <Container className={globalClasses.flex}>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            className={golbalClasses.dashbordBtn}
            onClick={handleBackClick}>
            <ArrowBackIosIcon onClick={handleBackClick} /> {t('Back')}
          </Button>
        </Box>
        <Box container className={classes.container}>
          <Box className={classes.flexRow}>
            <Box item className={classes.heading}>
              <Typography variant="h6" className={classes.textWhite}>
                {t('Quotation Details')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.form} style={{ height: '337px' }}>
            <Grid container spacing={1} mt={1} className={classes.section}>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Generated BY : {rowData.generatedBy ? rowData.generatedBy : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Inovice Number : {rowData.invoiceNo
 ? rowData.invoiceNo
 : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Phone Number : {rowData.phone
 ? rowData.phone
 : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Invocie Date  : {rowData.invoiceDateString
 ? rowData.invoiceDateString
 : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Invocie Status  : {rowData.status === 1?  "Active"
 : rowData.status=== 0 ?"Failed" : rowData.status===2? "Succes With Error": "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}>
                <Typography className={classes.headingText}>
                  Last Updated: {
                   ( formattedData && formattedData.refreshResponse) 
                      ? formatDate(formattedData.refreshResponse.lastUpdated)
                      : 'N/A'
                  }
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}></Grid>
             
               
            </Grid>
          </Box>
        </Box>

      

<DataTable
                  subHeader={true}
                  title={<TableHeader title={t('SKU Details')} />}
                  columns={columns}
                  data={rowData.skus ? rowData.skus : []}
                  pagination
                  // progressPending={loading}
                  progressComponent={<CustomLoader />}
                  // sortFunction={customSort}
                  // defaultSortField="email"
                  customStyles={customStyles}
                 
                  // selectableRows
                />
                  {/* Separate Tables for Arrays */}
          {formattedData && (
            <>
            <div style={{marginTop:"20px"}}>
              <DataTable
              style={{marginBottom:"20px" }}
                title={<TableHeader title={t('Missing SKU IDs')} />}
                columns={[{ name: 'SKU ID', selector: row => row }]}
                data={formattedData.refreshResponse.missingSkuIds}
                pagination
                customStyles={customStyles}
                noDataComponent="No Missing SKU IDs"
              />
</div>
              <DataTable
                style={{marginBottom:"20px"}}
                title={<TableHeader title={t('Missing Product IDs')} />}
                columns={[{ name: 'Product ID', selector: row => row }]}
                data={formattedData.refreshResponse.missingProductIds}
                pagination
                customStyles={customStyles}
                noDataComponent="No Missing Product IDs"
              />

              <DataTable
                title={<TableHeader title={t('Missing Stock IDs')} />}
                columns={[{ name: 'Stock ID', selector: row => row }]}
                data={formattedData.refreshResponse.missingStockIds}
                pagination
                customStyles={customStyles}
                noDataComponent="No Missing Stock IDs"
              />
            </>
          )}
      </Container>
    </>
  )
}

export default withTranslation()(ViewCustomerDetails)

