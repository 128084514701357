import React, { useEffect, useRef, useState } from 'react';
import { useMutation, gql, useQuery, useApolloClient } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { GetAllCustomerTypes, GetCustomerNestCash } from '../../apollo';
import { Button, Box, Typography, Grid, Paper, TextField, Autocomplete, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import { Icon } from '@iconify/react';
import useStyles from '../ChangeCustomerType/styles';
import useGlobalStyles from '../../utils/globalStyles';
import DataTable from 'react-data-table-component';
import TableHeader from '../TableHeader';
import { customStyles } from '../../utils/customTableForViewNestCash';
import CustomLoader from '../Loader/CustomLoader';

const GET_CUSTOMER_TYPE = gql`
  ${GetAllCustomerTypes}
`;
const GET_CUSTOMER_NESTCASH = gql`
  ${GetCustomerNestCash}
`;


const CustomPaperComponent = (props) => (
  <Paper {...props} style={{ background: 'white', color: 'black' }} />
);

function ViewNestCash(props) {
  const { onClose } = props;
  console.log(props.data)
  const formRef = useRef();
  let [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const [selectedWorker, setSelectedWorker] = useState(props.vendor ? props.vendor.storeName : null);
  const [formErrors, setFormErrors] = useState({});
  const client = useApolloClient();
  const [workerError, setWorkerError] = useState(false);
  const [formData, setFormData] = useState({
    customerType: props.vendor ? props.vendor.customerType : '',
  });
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };

  const [openSnackk, setopenSnackk] = useState(false);
  const [page, setPage] = useState(
    1
  );

  var [tabledata, setTabledata] = useState(null);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const variables = {
    customerId: props.data ? props.data._id : null ,
    limit: perPage,
    skip: (page - 1) * perPage,
    // skip: page,
  };
  const { data: customerWalletDetails ,loading :loadingQuery ,refetch} = useQuery(GET_CUSTOMER_NESTCASH, {
    variables: variables,
    onCompleted: (data) => {
      setTabledata(customerWalletDetails && customerWalletDetails.getCustomerNestCash && customerWalletDetails.getCustomerNestCash.transactions ? customerWalletDetails.getCustomerNestCash.transactions : [])
      
      setTotalRows(data.getCustomerNestCash.totalTxnCount);
    },
    onError: (error) => {
      const isForbiddenError = error && error.graphQLErrors.some((err) => err.extensions.code === 'FORBIDDEN');
      if (isForbiddenError) {
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      }
    },
  });

  const handlePerRowChange = (newPerPage) => {
    setPerPage(newPerPage)
  }
  // Inside your handlePageChange function, update the local storage
  const handlePageChange = (page) => {
    setPage(page);
    setCurrentPage(page)

  };
  useEffect(() => {
    refetch({
      variables: {
        limit: perPage,
        skip: (page - 1) * perPage,
      },
    });
  }, [page, perPage, refetch]);
  const optionsworkerStatus = customerWalletDetails
   
  const filtered = customerWalletDetails && customerWalletDetails.getCustomerNestCash && customerWalletDetails.getCustomerNestCash.transactions ? customerWalletDetails.getCustomerNestCash.transactions : [];

  console.log(filtered, "Filtered Transactions");

  console.log(optionsworkerStatus ,"optionsworkerStatus")






  const columns = [
    {
      name: "Sl No",
      selector: (row, index) => index + 1
    },
    {
      name: "Transaction ID",
      selector: (row) => row._id,  // Assuming each transaction has an _id
      style: { cursor: 'pointer', minWidth: "200px" },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      wrap: true
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true
    },
    {
      name: "Nest Balance",
      selector: (row) => row.nestBalance,
      sortable: true
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleString(), // Formatting date
      sortable: true
    },
    {
      name: "Expiry Date",
      selector: (row) => new Date(row.expiry).toLocaleDateString(), // Formatting expiry date
      sortable: true
    },
    {
      name: "Expired",
      selector: (row) => (row.expired ? "Yes" : "No"),
      sortable: true
    }
  ];
  
  return (
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    }} 
    maxWidth="lg" 
    fullWidth={true} 
    
    
    >
      <form ref={formRef} >
        <DialogTitle>
          <Box className={props.vendor ? classes.heading : classes.heading}>
            <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
              {props.vendor ? t('Edit Product') : t('Nest Cash Details ')}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {!props.vendor ?
              <>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                   Customer ID :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.customerId : "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                    phone:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>
                    <Icon icon="mdi:rupee" width="14" height="14" style={{ color: " black", }} />
                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ?customerWalletDetails.getCustomerNestCash.phone : "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                   Balance:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.balance  ?customerWalletDetails.getCustomerNestCash.balance  : "N/A": "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                    Expiry :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.expiry ? customerWalletDetails.getCustomerNestCash.expiry : "N/A" :"N/A"}
              
                  </Typography>
                </Grid>
                </Grid>

                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                    Active Txn Id :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.activeTxnId ? customerWalletDetails.getCustomerNestCash.expiry : "N/A" :"N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
                
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                    Apply Percent :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.applyPercent ? customerWalletDetails.getCustomerNestCash.applyPercent : "N/A" :"N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
                <Grid item xs={12} md={12} container direction="row" alignItems="center">
                  <Grid item xs={3} md={3}>
                    <Typography className={classes.typographyOrder} style={{ color: "black" }}>
                  Message :
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ fontWeight: 600, textAlign: "center", color: "black" }}>
                  <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', marginLeft: "-5px", fontSize: "14px" }}>

                    {customerWalletDetails && customerWalletDetails.getCustomerNestCash ? customerWalletDetails.getCustomerNestCash.message? customerWalletDetails.getCustomerNestCash.message: "N/A": "N/A"}
              
                  </Typography>
                </Grid>
                </Grid>
              </>
              :
              <>
                {/* Additional fields for vendor */}
              </>
            }
          </Grid>

          <DataTable
          subHeader={true}

          title={<TableHeader title={t('Trasactions')} />}
          columns={columns}
         
          data={tabledata ||[]}
          pagination
          progressPending={loadingQuery}
          progressComponent={<CustomLoader />}
          customStyles={customStyles}
          progressComponent={<CustomLoader />}
          paginationDefaultPage={currentPage}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowChange}
          onChangePage={handlePageChange}
          customStyles={customStyles}
        />
        </DialogContent>
        <DialogActions>
          {/* <Button
            type="submit"
            className={globalClasses.dashbordBtn}
            disabled={mutateLoading}
          >
            {props.vendor ? 'Update Product' : 'Save'}
          </Button> */}
          <Button
            className={globalClasses.modalCancleBtn}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </form>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}

export default withTranslation()(ViewNestCash);
