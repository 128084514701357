import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useApolloClient ,gql, useMutation } from '@apollo/client';
import { workerlogout, adminlogout } from '../../apollo'
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  AppBar,
  Box,
  Toolbar,
  useTheme
} from '@mui/material';
import { Icon } from '@iconify/react';


const LOGOUT_ADMIN = gql`
  ${adminlogout}
`

const LOGOUT_WORKER = gql`
  ${workerlogout}
`
function AdminNavbar(props) {
  const theme = useTheme();
  const client = useApolloClient();
  const userType  = JSON.parse(localStorage.getItem("user-native"))
  const userRole =localStorage.getItem('userRole')
  const [adminLogoutMutation] = useMutation(LOGOUT_ADMIN);
  const [workerLogoutMutation] = useMutation(LOGOUT_WORKER);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = props;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const userDetails = localStorage.getItem('user-native');
    let name
    //  imgUrl;
    
    if (userDetails) {
      const userDetailsObj = JSON.parse(userDetails);
      name = userDetailsObj.name;
      // imgUrl = userDetailsObj.profileURl;
    } else {
      // Handle the case when userDetails is null or undefined
      name = ''; // Or set a default value
      // imgUrl = ''; // Or set a default value
    }
    const logout = async () => {
      let logoutResponse;
      if( userRole === 'Admin'){
        if (userType.userType === 0  || userType.userType === 1 || userType.userType === 2) {
         
          logoutResponse = await adminLogoutMutation();
        } else {
          // For other user types (workers, riders, customers)
          logoutResponse = await workerLogoutMutation();
        }
      }else if(userType.workerType === 0 || userType.workerType === 1|| userType.workerType === 2){

          logoutResponse = await workerLogoutMutation();
        } else {
          // For other user types (workers, riders, customers)
          logoutResponse = await workerLogoutMutation();
        }
         // Check the response from the logout API
      if (logoutResponse.data.logoutAdmin || logoutResponse.data.logoutWorker) {
        // If logout was successful for admin or worker, perform additional logout logic
        localStorage.removeItem('user-native');
        localStorage.removeItem('restaurant_id');
        client.clearStore();
        props.history.push('/auth/login');
      } else {
        // Handle logout failure if needed
      }
    };;
       
  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'block' },
        flexGrow: 1,
        boxShadow: 0,
      }}
    >
      <AppBar position="static" sx={{ bgcolor: '#3E79F7', boxShadow: 0 }}>
        <Toolbar>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ flexGrow: 1, color: 'common.white', fontWeight: 'bold' }}
          >
           
     {userType.storeName} - {   userRole === 'Admin' ?  userType.userType === 0 ? "Super Admin" :userType.userType === 1 ? "Central Admin ": userType.userType === 2 ? " Local Admin" : ""  :userType.workerType === 0 ? "Package Admin" :userType.workerType === 1 ? "Billing Admin " : "" } -  {props.match.path === '/restaurant' ? '' : t(props.brandText)}
          </Typography>

          <div>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: 'white',
                paddingRight: '10px',
                borderRadius: '40px',
                height: 40,
                width: 250,
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="blue"
              >
                {/* <img
                  alt="..."
                  src={require('../../assets/img/theme/team-4-800x800.jpg')}
                  style={{
                    height: 35,
                    width: 35,
                    borderRadius: '50%',
                    marginLeft: -10,
                  }}
                /> */}

<Icon icon="iconamoon:profile-circle-fill" width="80" height="80"  style={{color: "black" , height: 35,
                    width: 35,
                    borderRadius: '50%',
                    marginLeft: -10,}} />
               
              </IconButton>
              <Typography mt={1} sx={{ fontWeight: 'bold' }} color="common.black">
           {name}
              </Typography>
            </Box>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
             
            
              <MenuItem
                sx={{ color: theme.palette.common.black }}
                onClick={(e) => {
                  e.preventDefault();
                  logout()
                  // props.history.push('/auth/login');
                }}
              >
                {t('Logout')}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    
    </Box>
  );
}

export default withTranslation()(AdminNavbar);
