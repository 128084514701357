
import React, { useRef, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { createcsadmin, modifyorder,ProcessOrderRefund } from '../../apollo';
import { Button, Box, Typography, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Snackbar } from '@mui/material';
import useStyles from './styles';
import useGlobalStyles from '../../utils/globalStyles';



const CREATE_LOCAL_STORE_ADMIN = gql`
  ${createcsadmin}
`;
const EDIT__LOCAL_STORE_ADMIN = gql`
  ${modifyorder}
`;

const EDIT_ORDER_REFUND = gql`
  ${ProcessOrderRefund}
`;
function EditOrderByWorker(props) {
  const { onClose, refetch ,payMethod ,sellingPrice } = props;
  const formRef = useRef();
  const mutation = props.vendor ? EDIT__LOCAL_STORE_ADMIN : CREATE_LOCAL_STORE_ADMIN;
  let [error, errorSetter] = useState('');
  // const [error, errorSetter] = useState('');
  const [success, successSetter] = useState('');
  const { t } = props;
  const userType = JSON.parse(localStorage.getItem("user-native"))
  const [openSnackk, setopenSnackk] = useState(false);
  const orderId = localStorage.getItem('orderId')
  const [formData, setFormData] = useState({
    id: userType._id,
    name: props.vendor ? props.vendor.name : '',
    quantity: props.vendor ? props.vendor.quantity : '',
    instruction: '',
    skuId: props.vendor ? props.vendor.skuId : '',
    productId: props.vendor ? props.vendor.productId : '',

  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'quantity' && parseInt(value) > props.vendor.quantity) {
      errorSetter("Please enter a quantity less than or equal to the current quantity.");
      setopenSnackk(true);
      setTimeout(() => {
        errorSetter('');
        setopenSnackk(false);
      }, 3000);
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onCompleted =async (data) => {
    if (!props.vendor) clearFields();
    const message = props.vendor ? t('Quantity Updated Successfullly') : t('StoreAddedSuccessfully');
    errorSetter('');
    successSetter(message);
    refetch()
    setopenSnackk(true)
    // setTimeout(hideAlert, 3000);
      // Conditionally call the ProcessOrderRefund mutation
      if (payMethod && payMethod === 1) {
        await processOrderRefund({
          variables: {
            orderId: orderId,
            refundAmount: sellingPrice,
            reason: formData.instruction,
            isOrderCancelled: parseInt(formData.quantity) === 0 ? true : false
          }
        });
      }
  };

  const onError = ({ graphQLErrors, networkError }) => {
    successSetter('');
    if (graphQLErrors) {
      error = graphQLErrors[0].message
      errorSetter("Something Went Wrong");
      setopenSnackk(true)
    }

    else if (networkError) errorSetter(networkError.result.errors[0].message);
    else errorSetter('Something went wrong!');
    setTimeout(hideAlert, 3000);
    setopenSnackk(true)
  };
  const [mutate] = useMutation(mutation, {
    onError,
    onCompleted,
  });
  const [processOrderRefund] = useMutation(EDIT_ORDER_REFUND, {
    onError,
    onCompleted: (data) => {
    }
  });
  const clearFields = () => {
    formRef.current.reset();
    setFormData({
      id: userType._id,
      name: props.vendor ? props.vendor.name : '',
      quantity: props.vendor ? props.vendor.quantity : '',
      instruction: '',
    })
  };

  const hideAlert = () => {
    errorSetter('');
    successSetter('');
    setopenSnackk(false)
  };

  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const inputTextStyle = { color: 'black' };


  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box className={props.vendor ? classes.heading : classes.heading}>
          <Typography className={props.vendor ? classes.textWhite : classes.textWhite}>
            {t('Edit Order Details ')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>

        <Grid container spacing={2}>  <>
          <Grid item xs={12} sm={6}>
            <TextField name="name" value={formData.name} label=" Name" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="quantity" value={formData.quantity} label="Quantity" variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }}
              helperText="**Enter '0' to delete item from order" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name="instruction" label="Comment" value={formData.email} variant="outlined" fullWidth onChange={handleInputChange} inputProps={{ style: inputTextStyle }} required />
          </Grid>

        </>

        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          className={globalClasses.dashbordBtn}
          // disabled={mutateLoading || !formData.instruction}
          onClick={async (e) => {
            e.preventDefault();
            if (!formData.instruction) {

              errorSetter("Please Fill Comment")
              setopenSnackk(true)
              setTimeout(() => {
                errorSetter('')
                setopenSnackk(false)
              }, 3000);
              return;
            } else {
              errorSetter('')
            }

            // Your existing mutation logic here
            !props.vendor ?
              mutate({
                variables: {
                  lsAdminInput: {
                    contactAddress: formData.contactAddress,
                    email: formData.email,
                    name: formData.name,
                    password: formData.password,
                    phone: formData.phone,
                    storeId: formData.storeId
                  }
                }

              }) :
              mutate({
                variables: {
                  replaceOrder: {
                    id: orderId,
                    instructions: formData.instruction,
                    itemInputs: [
                      {
                        productId: formData.productId,
                        quantity: parseInt(formData.quantity),
                        skuId: formData.skuId
                      }
                    ]
                  }
                }

              });
            // Close the modal after 3 seconds by calling the parent's onClose callback
            setTimeout(() => {
              if (typeof props.onClose === 'function') {
                props.onClose(); // Close the modal
              }
            }, 4000);
          }}
        >
          {props.vendor ? t('Update') : t('Save')}
        </Button>

        <Button
          className={globalClasses.modalCancleBtn}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      <Box mt={2}>
        {success && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="success" variant="filled" sx={{ width: '100%' }}>
              {success}
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={openSnackk} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Dialog>
  );
}
export default withTranslation()(EditOrderByWorker);
