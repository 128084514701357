import Login from './views/Login'
import Users from './views/Users'
import Vendors from './views/ManaggeCentralStore'
import AllOrderList from './views/AllOrderList'
import Products from '../src/views/Products'
import MangeCategory from '../src/views/ManageCategory'
import MangeSubCategory from '../src/views/ManageSubCategory'
import { ReactComponent as OrderIcon } from './assets/svg/order.svg'
import BurstModeIcon from '@mui/icons-material/BurstMode';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import CategoryIcon from '@mui/icons-material/Category';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SuperAdminDashboard from './views/SuperAdminDashboard'
import SingleOrderDetails from './views/SingleOrderDEtails'
import PackageAdminDashBoard from './views/PackageAdmin'
import PackageSingleORderDetails from "./views/PackageOrderDetails"
import PackageNewOrderList from "./views/PackageNewOrderList"
import PackagePackedOrderList from "./views/PackagePackedOrderList"
import ManageCatlog from './views/ManageCatlog'
import BillingadminDashboard from './views/BillingAdmin'
import CentralAdminDashBoard from './views/AdminDashbord'
import HomeIcon from '@mui/icons-material/Home';
import CentralAdminProducts from './views/CentralAdminProducts'
import MAnageLocalStore from './views/MAnageLocalStore'
import ManageLocalAdmins from './views/ManageLocalAdmins'
import LocalAdminProducts from './views/LocalAdminProducts'
import ManagelocalUsers from './views/ManagelocalUsers'
import LocalAdminDashboard from './views/LocalAdminDashboard'
import AllCustomerList from './views/AllCustomerList'
import ViewCustomerDetails from './views/ViewCustomerDetails'
import BasicTabs from './views/PackingWorkerTab'
import BillingWorkerTab from './views/BillingWorkerTab'
import PackageTabForProgress from './views/PackageTabForProgress'
import CSSku from './components/AddSKU/CSSku'
import CSRepletion from './views/CSRepletion'
import ManageReplenationForBillingAdmin from './views/ManageReplenationForBillingAdmin'
import ViewDetailsRepllenation from './views/ViewDetailsRepllenation'
import CreateNewReplentionRequest from './views/CreateNewReplentionRequest'
import CSAdminViewDetailsRepllenation from './views/CSAdminViewDetailsRepllenation'
import LSRepletion from './views/LSRepletion'
import WorkerReportForCSAdmin from './views/WorkerReportForCSAdmin'
import TimeSlotConfigLS from './views/TimeSlotConfigLS'
import Banner from './components/Vendor/Banner'
import LandingPage from './components/Vendor/LandingPage'
import CustomerCOnfiguration from './components/AdminConfiguration/CustomerCOnfiguration'
import BulkCreditToNestCash from './components/Vendor/BulkCreditToNestCash'
import CustomerTimeSlot from './components/AdminConfiguration/CustomerTimeSlot'
import RiderReport from './views/RiderReport'
import RiderReportList from './views/RiderReportList'
import RiderList from './views/RiderList'
import RiderOutForDelivery from './views/RiderOutForDelivery'
import ManageCustomerType from './views/ManageCustomerType'
import ManageCoupons from './views/ManageCoupons'
import DataRequestTab from './views/DataRequestTab'
import TopicIcon from '@mui/icons-material/Topic';
import CustomerTimeSlotForLS from './components/AdminConfiguration/CustomerTimeSlotForLS'
import ManageCustomerTimeSlot from './views/ManageCustomerTimeSlot'
import QuatationRequest from './views/QuatationRequest'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import QuatationDetails from './views/QuatationDetails'
import OrderStats from './views/OrderStats'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DayWiseOrderStats from './views/DayWiseOrderStats'
import UpgradeCustomer from './views/UpgradeCustomer'
import ManageOffer from './views/ManageOffer'
import AssignRider from './views/AssignRider'
import AasignRiderResultPage from './views/AasignRiderResultPage'
import ManageLandingPage from './components/Vendor/ManageLandingPage'
import OrderSTatusByIems from './views/OrderSTatusByIems'
// import { ReactComponent as Icon } from './assets/svg/.svg'

var routes = [
  {
    path: '/dashboard',
    name: 'Home',
    icon: HomeIcon,
    component: SuperAdminDashboard,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },

  {
    path: '/mangecategory',
    name: 'Manage Category',
    icon: CategoryIcon,
    component: MangeCategory,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/mangesubcategory',
    name: 'Manage Sub-Category',
    icon: SyncProblemIcon,
    component: MangeSubCategory,
    layout: '/super_admin',
    // appearInSidebar: true,
    admin: true
  },
  {

    path: '/manageCatalog',
    name: 'Manage Catalog',
    icon: HorizontalSplitIcon,
    component: ManageCatlog,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/products',
    name: 'Manage Products',
    icon: FactCheckIcon,
    component: Products,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {

    path: '/managestore',
    name: 'Manage Central Store',
    icon: StorefrontIcon,
    component: Vendors,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/users',
    name: 'Manage Admin User',
    icon: GroupIcon,
    component: Users,
    layout: '/super_admin',
    appearInSidebar: true,
    admin: true
  },
  {
    path: '/dashboard',
    name: 'Home',
    icon: HomeIcon,
    component: CentralAdminDashBoard,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/allorders',
    name: 'All Order',
    icon: FeaturedPlayListIcon,
    component: AllOrderList,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/datewiseorderstats',
    name: 'Sales Report',
    icon: QueryStatsIcon,
    component: DayWiseOrderStats,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/orderstats',
    name: ' Order Status',
    icon: QueryStatsIcon,
    component: OrderStats,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/orderstatusbyitem',
    name: ' Order Item Status ',
    icon: QueryStatsIcon,
    component: OrderSTatusByIems,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/allcustomers',
    name: 'All Customers',
    icon: GroupAddIcon,
    component: AllCustomerList,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/products',
    name: 'Manage Products',
    icon: FactCheckIcon,
    component: CentralAdminProducts,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/repletion',
    name: 'Manage Repletion',
    icon: SyncProblemIcon,
    component: CSRepletion,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/sku',
    name: 'Manage SKUS',
    icon: SyncProblemIcon,
    component: CSSku,
    layout: '/cs_admin',
    // appearInSidebar: true,
    centralAdmin: true
  },
  {

    path: '/managestore',
    name: 'Manage Local Store',
    icon: StorefrontIcon,
    component: MAnageLocalStore,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/users',
    name: 'Manage Admin User',
    icon: GroupIcon,
    component: ManageLocalAdmins,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/customertype',
    name: 'Customer Type',
    icon: GroupIcon,
    component: ManageCustomerType,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/OrderDetails',
    name: 'Order Details',
    icon: FeaturedPlayListIcon,
    component: SingleOrderDetails,
    layout: '/cs_admin',
    centralAdmin: true
  },
  {
    path: '/workerdetails',
    name: 'Worker Details',
    icon: 'ni ni-tv-2 text-primary',
    component: WorkerReportForCSAdmin,
    layout: '/cs_admin',
    centralAdmin: true
  },
  {
    path: '/banners',
    name: 'Banner',
    icon: BurstModeIcon,
    component: Banner,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/landingpage',
    name: 'Landing Page',
    icon: NewspaperIcon,
    component: LandingPage,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/managedisplaytype',
    name: 'Manage Display',
    icon: NewspaperIcon,
    component: ManageLandingPage,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/customerConfig',
    name: 'Customer Config',
    icon: ManageAccountsIcon,
    component: CustomerCOnfiguration,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
   {
    path: '/bulkCreditToNestCash',
    name: 'NestCash–BulkMode',
    icon: ManageAccountsIcon,
    component: BulkCreditToNestCash,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },

  {
    path: '/manageCoupons',
    name: 'Manage Coupons',
    icon: LocalOfferIcon,
    component: ManageCoupons,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/manageSpecialoffer',
    name: 'Special Offer',
    icon: LocalOfferIcon,
    component: ManageOffer,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/upgradecustomer',
    name: 'Upgrade Customer',
    icon: LocalOfferIcon,
    component: UpgradeCustomer,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/manageExtralData',
    name: 'Manage Data',
    icon: TopicIcon,
    component: DataRequestTab,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/manageQuotationData',
    name: 'Manage Quotation',
    icon: RequestQuoteIcon,
    component: QuatationRequest,
    layout: '/cs_admin',
    appearInSidebar: true,
    centralAdmin: true
  },
  {
    path: '/customerdetails',
    name: 'Customer Details',
    icon: 'ni ni-tv-2 text-primary',
    component: ViewCustomerDetails,
    layout: '/cs_admin',
    centralAdmin: true
  },
  {
    path: '/quotationdetails',
    name: 'Customer Details',
    icon: 'ni ni-tv-2 text-primary',
    component: QuatationDetails,
    layout: '/cs_admin',
    centralAdmin: true
  },
  // {
  //   path: '/customerTimeSlotConfig',
  //   name: 'Customer Time Slot',
  //   icon: AccessTimeIcon,
  //   component: CustomerTimeSlot,
  //   layout: '/cs_admin',
  //   appearInSidebar: true,
  //   centralAdmin: true
  // },
  // {
  //   path: '/customerTimeSlotConfig',
  //   name: 'Customer Time Slot',
  //   icon: AccessTimeIcon,
  //   component: ManageCustomerTimeSlot,
  //   layout: '/cs_admin',
  //   appearInSidebar: true,
  //   centralAdmin: true
  // },
  {
    path: '/detilerepletion',
    name: 'Details Repletion',
    icon: SyncProblemIcon,
    component: CSAdminViewDetailsRepllenation,
    layout: '/cs_admin',
    centralAdmin: true
  },
  {
    path: '/dashboard',
    name: 'Home',
    icon: HomeIcon,
    component: LocalAdminDashboard,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/allorders',
    name: 'All Order',
    icon: FeaturedPlayListIcon,
    component: AllOrderList,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/products',
    name: 'Manage Products',
    icon: FactCheckIcon,
    component: LocalAdminProducts,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/repletion',
    name: 'Manage Repletion',
    icon: SyncProblemIcon,
    component: LSRepletion,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },

  {
    path: '/OrderDetails',
    name: 'Order Details',
    icon: FeaturedPlayListIcon,
    component: SingleOrderDetails,
    layout: '/local_admin',
    localAdmin: true
  },
  {
    path: '/users',
    name: 'Manage  User',
    icon: GroupIcon,
    component: ManagelocalUsers,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/riderreport',
    name: 'Rider Report',
    icon: TwoWheelerIcon,
    component: RiderReportList,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },

  {
    path: '/assignrider',
    name: 'Assign Rider ',
    icon: TwoWheelerIcon,
    component: AssignRider,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/assignriderdetails',
    name: 'Assign Rider ',
    icon: TwoWheelerIcon,
    component: AasignRiderResultPage,
    layout: '/local_admin',
    // appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/riderreportDetails',
    name: 'Rider Report Details',
    icon: FeaturedPlayListIcon,
    component: RiderReport,
    layout: '/local_admin',
    localAdmin: true
  },
  {
    path: '/riderlist',
    name: 'Out For Delivery',
    icon: FeaturedPlayListIcon,
    component: RiderList,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/customerTimeSlotConfig',
    name: 'Customer Time Slot',
    icon: AccessTimeIcon,
    component: ManageCustomerTimeSlot,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },
  {
    path: '/rideroutfordeliverydetails',
    name: 'Rider Out For Delivery Details',
    icon: FeaturedPlayListIcon,
    component: RiderOutForDelivery,
    layout: '/local_admin',
    localAdmin: true
  },
  {
    path: '/ridertimeconfig',
    name: 'Time  Configuration',
    icon: AccessTimeIcon,
    component: TimeSlotConfigLS,
    layout: '/local_admin',
    appearInSidebar: true,
    localAdmin: true
  },

  {
    path: '/dashboard',
    name: 'Home',
    icon: HomeIcon,
    component: BillingadminDashboard,
    layout: '/billing_admin',
    appearInSidebar: true,
    billingAdmin: true
  },
  {
    path: '/billinglist',
    name: ' Order',
    icon: FeaturedPlayListIcon,
    component: BillingWorkerTab,
    layout: '/billing_admin',
    appearInSidebar: true,
    billingAdmin: true
  },
  {
    path: '/repletion',
    name: 'Manage Repletion',
    icon: SyncProblemIcon,
    component: ManageReplenationForBillingAdmin,
    layout: '/billing_admin',
    appearInSidebar: true,
    billingAdmin: true
  },
  {
    path: '/requestrepletion',
    name: 'Manage Repletion',
    icon: SyncProblemIcon,
    component: CreateNewReplentionRequest,
    layout: '/billing_admin',
    billingAdmin: true
  },
  {
    path: '/detilerepletion',
    name: 'Details Repletion',
    icon: SyncProblemIcon,
    component: ViewDetailsRepllenation,
    layout: '/billing_admin',
    billingAdmin: true
  },
  {
    path: '/OrderDetails',
    name: 'Order Details',
    icon: FeaturedPlayListIcon,
    component: PackageSingleORderDetails,
    layout: '/billing_admin',
    billingAdmin: true
  },
  {
    path: '/nweorderlist',
    name: 'In Progress Orders',
    icon: OrderIcon,
    component: PackageNewOrderList,
    layout: '/billing_admin',
    billingAdmin: true
  },

  {
    path: '/packedorderlist',
    name: 'Completed Orders',
    icon: OrderIcon,
    component: PackagePackedOrderList,
    layout: '/billing_admin',
    billingAdmin: true
  },
  {
    path: '/dashboard',
    name: 'Home',
    icon: HomeIcon,
    component: PackageAdminDashBoard,
    layout: '/package_admin',
    appearInSidebar: true,
    packageAdmin: true
  },
  {
    path: '/orderlist',
    name: ' Orders',
    icon: FeaturedPlayListIcon,
    component: BasicTabs,
    layout: '/package_admin',
    appearInSidebar: true,
    packageAdmin: true
  },
  {
    path: '/OrderDetails',
    name: 'Order Details',
    icon: FeaturedPlayListIcon,
    component: PackageSingleORderDetails,
    layout: '/package_admin',
    admin: true
  },
  {
    path: '/nweorderlist',
    name: 'In Progress Orders',
    icon: OrderIcon,
    component: PackageTabForProgress,
    layout: '/package_admin',
    packageAdmin: true
  },

  {
    path: '/packedorderlist',
    name: 'Completed Orders',
    icon: OrderIcon,
    component: PackagePackedOrderList,
    layout: '/package_admin',
    packageAdmin: true
  },



  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    appearInSidebar: false
  },



]
export default routes
