import * as React from 'react'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Link,
  SvgIcon,
  useTheme
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import routes from '../../routes'
import useStyles from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import logo from '../../assets/svg/roostyicon.png'
import LogoutIcon from '@mui/icons-material/Logout';
import { useApolloClient, gql, useMutation } from '@apollo/client';
import { workerlogout, adminlogout } from '../../apollo'



const LOGOUT_ADMIN = gql`
  ${adminlogout}
`

const LOGOUT_WORKER = gql`
  ${workerlogout}
`

const drawerWidth = 240
function LocalAdminSideBar(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation()
  const classes = useStyles()
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const userType = JSON.parse(localStorage.getItem("user-native"))
  const client = useApolloClient();
  const userRole = localStorage.getItem('userRole')
  const history = useHistory();
  const [adminLogoutMutation] = useMutation(LOGOUT_ADMIN);
  const [workerLogoutMutation] = useMutation(LOGOUT_WORKER);

  const logout = async () => {
    let logoutResponse;
    if (userRole === 'Admin') {
      if (userType.userType === 0 || userType.userType === 1 || userType.userType === 2) {
        logoutResponse = await adminLogoutMutation();
      } else {
        logoutResponse = await workerLogoutMutation();
      }
    } else if (userType.workerType === 0 || userType.workerType === 1 || userType.workerType === 2) {

      logoutResponse = await workerLogoutMutation();
    } else {
      logoutResponse = await workerLogoutMutation();
    }

    // Check the response from the logout API
    if (logoutResponse.data.logoutAdmin || logoutResponse.data.logoutWorker) {
      // If logout was successful for admin or worker, perform additional logout logic
      localStorage.removeItem('user-native');
      localStorage.removeItem('restaurant_id');
      client.clearStore();
      history.push('/auth/login');
    } else {
    }

  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const createLinks = (

    <Box className={classes.sidebarBox}>
      <Toolbar className={[classes.rowDisplay, classes.logo]}>
      <img alt='images' src={"https://cdn.nativenest.in/pro/logo/Native-Nest-Logo.jpg"} style={{ height: "50px", width: "50px" }} />
       
       <Typography
          variant="h2"
          className={[classes.headingText, classes.logoText]}>
          NativeNest <br />
          Local Admin
        </Typography>
      </Toolbar>
      <Box className={classes.sidebarList}>
        {routes.map((prop, key) => {
          return prop.appearInSidebar && prop.localAdmin ? (
            <>

              <Link
                className={[
                  classes.rowDisplay,
                  classes.sidebarLink,
                  location.pathname === `${prop.layout}${prop.path}` &&
                  classes.active
                ]}
                href={'#' + prop.layout + prop.path}
                underline="none">
                <SvgIcon
                  component={prop.icon}
                  htmlColor="red"
                  fontSize="small"
                />
                <Typography
                  variant="h6"
                  className={[
                    classes.linkText,
                    location.pathname !== `${prop.layout}${prop.path}`
                      ? classes.blackText
                      : classes.blueText
                  ]}>
                  {t(prop.name)}
                </Typography>
              </Link>
            </>
          ) : null
        })}
        <Link onClick={(e) => {
          e.preventDefault();
          logout()
        }}
          className={[
            classes.rowDisplay,
            classes.sidebarLink,
          ]}
          underline="none">
          <SvgIcon
            component={LogoutIcon}
            htmlColor="black"
            fontSize="small"
          />
          <Typography
            variant="h6"
            className={[
              classes.linkText,
              classes.blackText
            ]}
            sx={{ whiteSpace: 'nowrap' }}>
            Logout
          </Typography>
        </Link>
      </Box>
    </Box>
    // </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      {/* <CssBaseline /> */}
      <AppBar
        mb={10}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          display: { sm: 'none' },
          bgcolor: 'primary.main2',
          marginBottom: '100px'
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '70%' }
          }}>
          {createLinks}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            backgroundColor: 'red',
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              background:
                `linear-gradient(180deg, ${theme.palette.warning.dark} 50%, transparent 50%)`,
              borderRight: 'none'
            }
          }}
          open>
          {createLinks}
        </Drawer>
      </Box>
    </>
  )
}

export default withTranslation()(LocalAdminSideBar)
